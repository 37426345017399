<template>
  <v-row>
    <v-col cols="12">
      <h2 class="hello__text">
        Hello {{ authUser.name }}!
      </h2>
      <h3 class="mt-4 hello__text">
        Free tools
      </h3>
    </v-col>

    <v-col
      cols="12"
      md="8"
    >
      <v-row>
        <!-- tweet card -->
        <v-col
          cols="12"
          md="6"
        >
          <v-card>
            <v-card-title class="align-center">
              <img
                class="mr-3"
                width="35"
                height="35"
                :src="require('@/assets/images/svg/twitter-icon.svg')"
              />
              TweetShots
              <!-- <v-spacer></v-spacer>
              <v-btn
                icon
                small
                class="me-n3 mt-n2"
              >
                <v-icon size="22">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn> -->
            </v-card-title>
            <v-card-text>
              Convert your favorite Tweets into beautiful images ready to be shared on other channels.
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary lighten-2"
                plain
                class="ma-0 px-0"
                :to="{name : 'twitter-images'}"
              >
                Take a shot
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <!-- browsershots card -->
        <v-col
          cols="12"
          md="6"
        >
          <v-card>
            <v-card-title class="align-center">
              <img
                class="mr-3"
                width="35"
                height="35"
                :src="require('@/assets/images/browsershots/browsershot-icon.svg')"
              />
              BrowserShots
            </v-card-title>
            <v-card-text>Create in 1 click awesome images of your product within browser mockups</v-card-text>

            <v-card-actions>
              <v-btn
                color="primary lighten-2"
                plain
                class="ma-0 px-0"
                :to="{name : 'browsershots'}"
              >
                Take a shot
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <!-- quote card -->
        <!-- <v-col
          cols="12"
          md="6"
        >
          <v-card>
            <v-card-title class="align-center">
              <img
                class="mr-3"
                width="35"
                height="35"
                :src="require('@/assets/images/svg/quote-icon.svg')"
              />
              Quote Shots
            </v-card-title>
            <v-card-text>Convert any Quote into a beautiful image ready to boost your engagement on Social Networks.</v-card-text>
            <v-card-actions>
              <v-btn
                color="primary lighten-2"
                plain
                class="ma-0 px-0"
              >
                Coming soon
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col> -->

        <!-- zero blur card -->
        <v-col
          cols="12"
          md="6"
        >
          <v-card>
            <v-card-title class="align-center">
              <img
                class="mr-3"
                width="35"
                height="35"
                :src="require('@/assets/images/svg/zeroblur-icon.svg')"
              />
              Zero Blur
              <!-- <v-spacer></v-spacer>
              <span
                class="percentage text-xs mb-2 success--text"
              > +21%</span> -->
            </v-card-title>
            <v-card-text>
              Never again expose sensitive data while sharing your screen.
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary lighten-2"
                plain
                class="ma-0 px-0"
              >
                Install <v-icon>{{ icons.mdiDownload }}</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <!-- saas example card -->
        <v-col
          cols="12"
          md="6"
        >
          <v-card>
            <v-card-title class="align-center">
              <img
                class="mr-3"
                width="35"
                height="35"
                :src="require('@/assets/images/svg/saas-icon.svg')"
              />
              SaaS Examples
            </v-card-title>
            <v-card-text>Hundreds of examples organized by company, industry, or page type!</v-card-text>
            <v-card-actions class="px-0">
              <a
                class="text-decoration-none"
                href="https://divbyzero.com/tools/saas/"
                target="_blank"
              >
                <v-btn

                  plain
                  color="primary lighten-2"
                >
                  Browse
                </v-btn>
              </a>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <!-- user plan card -->
    <v-col
      cols="12"
      md="4"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <span>Your plan: Novice</span>
          <v-btn
            text
            elevation="1"
            class="text__btn"
          >
            100% FREE
          </v-btn>
        </v-card-title>
        <v-card-text>Images per month <strong class="float-right">{{ limits.maxImages }}</strong> </v-card-text>

        <v-card-text class="mt-2">
          All our tools 100% free. Need higher limits?
        </v-card-text>
        <v-card-text class="mt-2">
          <strong>Refer friends to increase your limits and win monthly prizes</strong>
        </v-card-text>
        <v-card-actions class="mt-2">
          <v-btn
            color="primary"
            large
            elevation="2"
            :to="{ name : 'user-settings' }"
          >
            Refer a friend
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-col cols="12">
      <h3 class="hello__text">
        What’s Next
      </h3>
    </v-col>

    <!-- upcoming card -->
    <v-col
      cols="12"
      md="8"
    >
      <upcoming-card></upcoming-card>
    </v-col>

    <!-- logs card -->
    <v-col
      cols="12"
      md="4"
    >
      <logs-card></logs-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiDotsVertical, mdiDownload } from '@mdi/js'
import { computed } from '@vue/composition-api'
import LogsCard from './components/LogsCard.vue'
import UpcomingCard from './components/UpcomingCard.vue'

export default {
  components: {
    UpcomingCard,
    LogsCard,
  },
  setup(props, { root: { $store } }) {
    const authUser = computed(() => $store.state.auth.user)
    const limits = computed(() => $store.state.social.limits)

    return {
      authUser,
      limits,
      icons: {
        mdiDotsVertical,
        mdiDownload,
      },
    }
  },
}
</script>

<style scoped lang="scss">
.hello__text {
  font-weight: 700;
  color: #000000;
}
.text__btn {
  border-radius: 8px;
  color: #c61044;
}
</style>
