<template>
  <v-card :loading="loading">
    <v-card-title>What’s new?</v-card-title>
    <v-card-text>
      <v-timeline
        dense
        class="timeline-custom-dense timeline-custom-dots"
      >
        <v-timeline-item
          v-for="log in logs"
          :key="log.id"
          small
          color="error"
        >
          <div class="d-flex justify-space-between align-center flex-wrap">
            <h4 class="font-weight-semibold me-1">
              {{ log.title }}
            </h4>
            <v-chip
              color="info"
              outlined
              small
              class="mr-4"
            >
              {{ log.tool }}
            </v-chip>
          </div>
          <p class="mb-0">
            {{ log.short_description }}
          </p>
          <div class="d-flex align-center mt-2">
            <v-icon
              color="error"
              class="me-1"
            >
              {{ icons.mdiCalendar }}
            </v-icon>
            <span>
              {{ moment(log.created_at).fromNow() }}
            </span>
          </div>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCalendar } from '@mdi/js'
import { ref } from '@vue/composition-api'
import apiClient from '@/services'

export default {
  setup() {
    const loading = ref(false)

    const logs = ref([])

    const getLogs = () => {
      loading.value = true
      apiClient
        .getChangeLog()
        .then(response => {
          logs.value = response.data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          loading.value = false
        })
    }
    getLogs()

    return {
      loading,
      logs,
      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>
