<template>
  <v-card :loading="loading">
    <v-card-text>
      <v-row
        v-for="upcoming in upcomings.filter((item)=>(item.is_next === 1))"
        :key="upcoming.id"
      >
        <v-col cols="8">
          <v-card-title class="align-center">
            <img
              v-if="upcoming.icon_path"
              class="mr-3"
              width="35"
              height="35"
              :src="__serverImage(upcoming.icon_path)"
            />
            <v-icon
              v-else
              size="30"
              class="mr-3"
            >
              {{ icons.mdiMonitorDashboard }}
            </v-icon>
            {{ upcoming.title }}
          </v-card-title>
          <v-card-text
            class="font-weight-semibold"
            v-html="upcoming.description"
          >
          </v-card-text>

          <v-card-actions class="mt-10">
            <v-btn
              color="primary lighten-2"
            >
              Notify Me
            </v-btn>
          </v-card-actions>
        </v-col>

        <v-col

          cols="4"
          class="px-4 text-center"
        >
          <v-img
            class="mx-2"
            max-width="400"
            :src="upcoming.image_path ? __serverImage(upcoming.image_path) : require('@/assets/images/auth/bg-main-object.png')"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-divider></v-divider>
    </v-card-text>

    <v-card-title>Vote the next tool</v-card-title>

    <v-card-text>
      <v-row class="border__odd">
        <v-col
          v-for="upcoming in upcomings.filter((item)=>(item.is_next === 0))"
          :key="upcoming.id"
          cols="12"
          md="6"
          class="col mb-2"
        >
          <v-chip
            color="info"
            outlined
            small
            class="float-right mr-4"
          >
            {{ upcoming.totalVotes }} votes
          </v-chip>
          <v-card-title>{{ upcoming.title }}</v-card-title>
          <v-card-text>{{ upcoming.description }}</v-card-text>
          <v-card-actions>
            <v-btn
              color="primary lighten-2"
              :loading="loading"
              outlined
              small
              :plain="upcoming.voted"
              @click="submitVote(upcoming)"
            >
              {{ upcoming.voted ? 'Voted' : 'I want this' }}
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text>
      <v-divider></v-divider>
    </v-card-text>

    <!-- user upcoming form -->
    <v-card-title>Submit Your Idea!</v-card-title>

    <v-card-text>
      <v-alert
        v-if="upcomingFormResponse.message"
        :color="upcomingFormResponse.error ? 'error' : 'success'"
        text
        dark
        class="my-4"
      >
        {{ upcomingFormResponse.message }}
      </v-alert>

      <v-form
        ref="upcomingForm"
        @submit.prevent="submitUserUpcoming"
      >
        <v-textarea
          v-model="description"
          outlined
          placeholder="Have a great idea for a simple tool that other SaaS marketers could benefit from? Share it here and we might build it!"
          :rules="[validators.required]"
        ></v-textarea>

        <v-btn
          color="primary"
          outlined
          type="submit"
          :loading="loading"
        >
          Submit Idea
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import apiClient from '@/services'
import { required } from '@core/utils/validation'
import { mdiMonitorDashboard } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const loading = ref(false)
    const upcomings = ref([])

    const upcomingForm = ref(null)
    const upcomingFormResponse = ref({
      error: false,
      message: null,
    })
    const description = ref(null)

    const getUpcoming = () => {
      loading.value = true
      apiClient
        .getUpcoming()
        .then(response => {
          upcomings.value = response.data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          loading.value = false
        })
    }
    getUpcoming()

    const submitVote = upcoming => {
      loading.value = true
      apiClient
        .toggleUpcomingVote({ upcoming_id: upcoming.id })
        .then(() => {
          getUpcoming()
        })
        .catch(err => {
          console.log(err)
          loading.value = false
        })
    }

    const submitUserUpcoming = () => {
      const isFormValid = upcomingForm.value.validate()
      if (!isFormValid) return false
      loading.value = true
      apiClient
        .saveUserUpcoming({ description: description.value })
        .then(response => {
          description.value = null
          upcomingFormResponse.value.error = false
          upcomingFormResponse.value.message = response.data.message || 'Your idea successfully submitted!'
        })
        .catch(err => {
          upcomingFormResponse.value.error = true
          upcomingFormResponse.value.message = err.response.data.error || err.response.data.message
        })
        .finally(() => {
          loading.value = false
          setTimeout(() => {
            upcomingFormResponse.value.message = null
          }, 2000)
        })

      return true
    }

    return {
      loading,
      upcomings,

      submitVote,

      upcomingForm,
      description,
      submitUserUpcoming,
      upcomingFormResponse,
      validators: {
        required,
      },
      icons: {
        mdiMonitorDashboard,
      },
    }
  },
}
</script>
<style scoped>
.border__odd .col:nth-child(odd) {
  border-right: 1px solid;
  border-color: rgba(94, 86, 105, 0.14);
}
</style>
